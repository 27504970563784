import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/AddVenue.css';

function AddVenue() {
    const [newVenueName, setVenueName] = useState('');
    const [newVenueAddress, setVenueAddress] = useState('');
    const navigate = useNavigate();
    let { user_id } = useParams();

    const handleAddVenue = async () => {
        await fetch(`https://junbispark.net/add_venue/?venue_name=${newVenueName}&venue_address=${newVenueAddress}`)
            .then(async () => {
                await fetch(`https://junbispark.net/select_venue/?user_id=${user_id}&venue_name=${newVenueName}`)
                    .then(() => {
                        navigate(`/venue_account/${user_id}`);
                    });
            });
    };

    const handleBack = () => {
        navigate(-1); // This will take the user to the previous page
    };

    return (
        <div className="add-venue-container">
            <div className="logo">JUNBI SPARK</div>
            <h1>Add Your Venue</h1>
            <div className="form-group">
                <input
                    type="text"
                    id="venue_name"
                    name="venue_name"
                    placeholder="Enter the name of your venue"
                    onChange={(e) => setVenueName(e.target.value)}
                />
            </div>
            <div className="form-group">
                <input
                    type="text"
                    id="venue_address"
                    name="venue_address"
                    placeholder="Enter the address of your venue"
                    onChange={(e) => setVenueAddress(e.target.value)}
                />
            </div>
            <button type="button" className="submit" onClick={handleAddVenue}>
                Submit
            </button>
            <button type="button" className="tertiary-button" onClick={handleBack}>Back</button>
        </div>
    );
}

export default AddVenue;
