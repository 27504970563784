import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/SignInPage.css'; 
import { auth, googleProvider } from "../config/firebase";
import { signInWithPopup, signOut, GoogleAuthProvider, signInWithEmailAndPassword} from "firebase/auth";
import Cookies from "js-cookies";

const SignInPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const navigate = useNavigate();
    const signIn = async () => {console.log("signup func")
        try {
            await signInWithEmailAndPassword(auth, email, password).then(
                async (userCredential) => {
                    const user = userCredential.user;
                    return user.getIdToken();
                }).then(
                    (idToken) => {
                        fetch("https://junbispark.net/sign_in_user/?idtoken="+encodeURIComponent(idToken), {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json",
                                "Access-Control-Allow-Credentials":true,
                            },
                            credentials: "include"
                        }).then(
                            (response) => {return response.json()}
                        ).then(
                            (response) => {
                                if (response['status'] == 'success') {
                                    const user_id_response = response['user_id'];
                                    Cookies.setItem('session_cookie', response['cookie'], response['time_limit_seconds'])
                                    navigate(`/dashboard/${user_id_response}`)
                                }
                            }
                        ).catch(() =>{
                            setEmailError('The email and password you entered did not match our records. Please double-check and try again.');

                        })
                    }
                )    
        } catch (err) {
            setEmailError('The email and password you entered did not match our records. Please double-check and try again.');
            console.log('Error hitting the sign in endpoint', err);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        let valid = true;

        if (!email) {
            setEmailError('Email is required.');
            valid = false;
        } else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('Password is required.');
            valid = false;
        } else {
            setPasswordError('');
        }

        if (valid) {
            // Handle successful form submission here
            console.log('Form submitted.');
            signIn();
        }
    };

    const handleCancel = () => {
        navigate('/');
    };

    useEffect(() => {
        document.body.classList.add('sign-in-body');
        return () => {
          document.body.classList.remove('sign-in-body');
        };
      }, []);

    return (
        <body class="sign-in-body">
        <div className="sign-in-container">
            <div className="logo"><a href="/">BestDay2Marry</a></div>
            <div className="sign-in-form">
                <h1>Sign In</h1>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={emailError ? 'error' : ''}
                        />
                        {emailError && <div className="error-message">{emailError}</div>}
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={passwordError ? 'error' : ''}
                        />
                        {passwordError && <div className="error-message">{passwordError}</div>}
                    </div>
                    <button type="submit" className="primary-button">Submit</button>
                    <button type="button" className="tertiary-button" onClick={handleCancel}>Cancel</button>
                    <div class="sign-up-container">
                        <p id="sign-up-msg">Don't have an account?&nbsp;<a href="/signup" className="sign-up-link"> Sign up</a></p>
                        <div className="alternative-sign-in">
                            {/* <button className="google-sign-in-button">
                                <img src="google-icon.png" alt="Google Icon" />
                                Sign in with Google
                            </button> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </body>
    );
};

export default SignInPage;