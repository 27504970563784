// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getAuth, GoogleAuthProvider, PhoneAuthProvider} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// import firebase from 'firebase/app';
// import {firebase} from "firebase";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsJ9vNGTfE7bzJu3xpHe4nRiyN6Uy0QUg",
  authDomain: "trial-6f061.firebaseapp.com",
  projectId: "trial-6f061",
  storageBucket: "trial-6f061.appspot.com",
  messagingSenderId: "530908826919",
  appId: "1:530908826919:web:764b60113360ca340c6d02",
  measurementId: "G-GZMT8X6QWZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const phoneProvider = new PhoneAuthProvider();
export const db = getFirestore();
