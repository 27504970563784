import React from 'react';
import '../styles/Header.css';

function Header() {
  return (
    <header className="header-container">
      <div className="header-nav-container">
        <div className="header-text">
          <h1 className="logo">
            <a href="/">BestDay2Marry</a>
          </h1>
        </div>

        <nav className="nav">
          <a href="/">About</a>
          <a href="/solutions">Solutions</a>
          <div className="dropdown">
            <a href="/pricing/businesses">Pricing</a>
            <div className="dropdown-content">
              <a href="/pricing/businesses">Businesses</a>
              <a href="/pricing/couples">Couples</a>
            </div>
          </div>
          <a href="/signin">Sign In</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;