import React, { useEffect, useState, forwardRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Label, AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/ExpandedTracker.css';
import IconUsers from '../assets/icon-users.png';
import IconTracker from '../assets/icon-tracker.png';
import IconAQI from '../assets/icon-aqi.png';
import IconWind from '../assets/icon-wind-color.png';
import IconRain from '../assets/icon-rain-color.png';
import IconTemp from '../assets/icon-temp-color.png';
import IconTempMax from '../assets/icon-temp-max-color.png';
import IconTempMin from '../assets/icon-temp-min-color.png';
import IconTempMaxLite from '../assets/icon-temp-max-lite.png';
import IconTempMinLite from '../assets/icon-temp-min-lite.png';
import IconSolar from '../assets/icon-solar.png';
import IconCloud from '../assets/icon-cloud.png';
import IconHumidity from '../assets/icon-humidity.png';
import IconWindLite from '../assets/icon-wind-lite.png';
import IconRainLite from '../assets/icon-rain-lite.png';
import IconTempLite from '../assets/icon-temp-lite.png';
import IconSolarLite from '../assets/icon-solar-lite.png';
import IconCloudLite from '../assets/icon-cloud-lite.png';
import IconHumidityLite from '../assets/icon-humidity-lite.png';
import { useAuth, VenueContext } from '../utils/authprovider';
import { fetchVenueForUser, saveVenueToUser } from '../utils/queries';
import IconCog from '../assets/icon-cog.png';
import IconHome from '../assets/icon-home.png';
import IconPin from '../assets/icon-pin.png';
import IconCal from '../assets/icon-cal.png';
import { Routes, Route } from 'react-router-dom';
import SettingsPage from './SettingsPage';
import { getVenuesList } from './VenueSelection';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import Cookies from "js-cookies";

export const TEMP_UNIT_KEY = 't2m_units';
export const PRECIP_UNIT_KEY = 'precipitation_units';
export const WIND_UNIT_KEY = 'ws2m_units';
export const CELSIUS = 'C';
export const FAHRENHEIT = 'F';
export const INCHES = 'inches';
export const CM = 'cm';
export const MPH = 'mph';
export const KMPH = 'kmph';
export const IMPERIAL = 'imperial';
export const METRIC = 'metric';

export const IMPERIAL_UNITS = {
    [TEMP_UNIT_KEY]: FAHRENHEIT,
    [PRECIP_UNIT_KEY]: INCHES,
    [WIND_UNIT_KEY]: MPH,
}

export const METRIC_UNITS = {
    [TEMP_UNIT_KEY]: CELSIUS,
    [PRECIP_UNIT_KEY]: CM,
    [WIND_UNIT_KEY]: KMPH,
}

const ONE_DAY_FUTURE = 1000 * 60 * 60 * 24;
const ONE_WEEK_FUTURE = 1000 * 60 * 60 * 24 * 7;
const TWO_WEEK_FUTURE = 1000 * 60 * 60 * 24 * 14;
const THREE_WEEK_FUTURE = 1000 * 60 * 60 * 24 * 21;



const CustomDatePickerInput = forwardRef(({ value, onClick, placeholder, className }, ref) => (
    <div className="custom-date-picker-input" onClick={onClick} ref={ref}>
        <img src={IconCal} alt="Calendar Icon" className={`date-picker-icon ${className}`} />
        <input
            type="text"
            value={value}
            readOnly
            className={`date-picker-text-input ${className}`}
            placeholder={placeholder}
        />
    </div>
));

// Utility function to calculate interval
const calculateInterval = (startDate, endDate) => {
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (ONE_DAY_FUTURE));
    if (diffDays <= 7) {
        return 0; 
    } 
    
    else if (diffDays <= 30) {
        return 1; 
    } 
    
    else if (diffDays <= 90) {
        return 2;  
    } 
    
    else if (diffDays <= 180) {
        return 5;  
    } 
    
    else if (diffDays <= 365) {
        return 10;  
    } 
    
    else {
        return 20;  
    }
};

// function to convert wind direction
const getWindDirection = (angle) => {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    const index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
    return directions[index];
};

// startDate and endDate must be Date objects
const fetchDataFromServer = async (user_id, startDate, endDate, unitSettings) => {
    // If end date is not set then default to end date being one day after start date
    if (endDate === null) {
        endDate = new Date(startDate.getTime() + ONE_DAY_FUTURE);
    }

    if (!startDate || !endDate) {
        console.log('Error both start date and end date are required in fetchDataFromServer got startDate:', startDate, 'endDate:', endDate);
        return;
    }
    const convertDate = (dateObject) => {
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const start = convertDate(startDate);
    const end = convertDate(endDate); 
    // const venue = venueName['value'];
    console.log('START:', start, 'END:', end, 'unitSettings', unitSettings);
    let data = null;
    try {
        let scale = 'daily'
        if (endDate - startDate <= ONE_DAY_FUTURE) {
            scale = 'hourly'
        }
        const session_cookie = Cookies.getItem("session_cookie");
        const url = `https://junbispark.net/get_dashboard_data/?user_id=${user_id}&start_time=${start}&end_time=${end}&scale=${scale}&session_cookie=${session_cookie}&t2m_units=${unitSettings[TEMP_UNIT_KEY]}&precipitation_units=${encodeURIComponent(unitSettings[PRECIP_UNIT_KEY])}&ws2m_units=${unitSettings[WIND_UNIT_KEY]}`
        console.log('URL', url);
        const response = await fetch(url);
        data = await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        return;
    }

    console.log('DATA:', data);

    data = data['rv']
    const mappedData = data.map(item => {
        const timestamp = item['time'];

        return {
            date: new Date(timestamp),
            precipitation: item['PRECTOTCORR_Average'],
            precipitationMin: item['PRECTOTCORR_Min'],
            precipitationMax: item['PRECTOTCORR_Max'],
            temperature: item['T2M_Average'],
            temperatureMin: item['T2M_Min'],
            temperatureMax: item['T2M_Max'],
            windSpeed: item['WS2M_Average'],
            windSpeedMin: item['WS2M_Min'],
            windSpeedMax: item['WS2M_Max'],
            windDirection: item['WD2M_Average'],
            windDirectionMin: item['WD2M_Min'],
            windDirectionMax: item['WD2M_Max'],
            watts: item['ALLSKY_SFC_SW_DWN_Average'],
            wattsMin: item['ALLSKY_SFC_SW_DWN_Min'],
            wattsMax: item['ALLSKY_SFC_SW_DWN_Max'],
            cloud: item['CLOUD_AMT_Average'],
            cloudMin: item['CLOUD_AMT_Min'],
            cloudMax: item['CLOUD_AMT_Max'],
            rh2m: item['RH2M_Average'],
            rh2mMin: item['RH2M_Min'],
            rh2mMax: item['RH2M_Max'],
        };
    });

    console.log('MAPPED DATA:', mappedData);

    const sortedData = mappedData.sort((a, b) => a.date - b.date);
    console.log('SORTED DATA:', sortedData);

    return sortedData;
};

export const fetchUnitSettingsForUser = async (userId) => {
    try {
        const url = `https://junbispark.net/get_unit_settings/?user_id=${userId}`
        const response = await fetch(url)
        const result = await response.json()
        if (result['status'] == 'success') {
           delete result.status
           // Used for debugging if result is missing an expected key then print it
           if (!result[TEMP_UNIT_KEY] || !result[PRECIP_UNIT_KEY] || !result[WIND_UNIT_KEY]) {
               console.log('Missing unit settings for user', userId, ':', result);
           }
           return result
        }
    } catch (error) {
        console.error('Error fetching unit settings for user', userId, ':', error);
    }
    return null;
}

export const saveUnitsToBackend = async (userId, units) => {
    try {
        const url = `https://junbispark.net/update_unit_settings/?user_id=${userId}&precipitation_units=${units[PRECIP_UNIT_KEY]}&t2m_units=${units[TEMP_UNIT_KEY]}&ws2m_units=${units[WIND_UNIT_KEY]}`
        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(units)
        })
        const result = await response.json()
        console.log('SAVING UNITS RESULT', result)
    } catch (error) {
        console.error('Error fetching unit settings for user', userId, ':', error);
    }
    console.log('Successfully saved units to backend:', units);
    return null;
}

const filterDataByDateRange = (data, start, end) => {
    const filtered = data.filter(item => item.date >= start && item.date <= end).sort((a, b) => a.date - b.date);
    return filtered;
};

const CustomTooltip = ({ active, payload, label, minKey, maxKey }) => {
    if (active && payload && payload.length) {
        const dataPoint = payload[0].payload;
        return (
            <div className="custom-tooltip">
                <p className="label">{`Date: ${label}`}</p>
                <p className="intro">{`${payload[0].name}: ${payload[0].value}`}</p>
                <p className="intro">{`Min: ${dataPoint[minKey]}`}</p>
                <p className="intro">{`Max: ${dataPoint[maxKey]}`}</p>
            </div>
        );
    }
    return null;
};

const ExpandedTracker = ({ trackers, onSave, settings, activeVenue }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [insights, setInsights] = useState(null);
    const [timeRange, setTimeRange] = useState('1W'); // '1D', '3D', '1W', '1M'
    // const [tracker, setTracker] = useState(trackers.length > 0 ? trackers[0] : null);
    // const [editMode, setEditMode] = useState(false);
    const [weekData, setWeekData] = useState([]);
    const [monthData, setMonthData] = useState([]);
    // const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [serverData, setServerData] = useState([]);
    const [activeTab, setActiveTab] = useState('temperature');
    const [interval, setInterval] = useState(0);

    const [startDate, setStartDate] = useState(new Date(new Date().getTime() + TWO_WEEK_FUTURE));
    const [endDate, setEndDate] = useState(new Date(new Date().getTime() + THREE_WEEK_FUTURE));

    // const currentVenue = useContext(VenueContext)
    const [selectedVenue, setSelectedVenue] = useState('');
    const [venueList, setVenueList] = useState([]);

    const [unitSettings, setUnitSettings] = useState(IMPERIAL_UNITS);
    const [unitSystem, setUnitSystem] = useState(IMPERIAL);

    // console.log('-----------EXPANDED TRACKER VENUE CONTEXT:', currentVenue);

    let { user_id } = useParams();

    const calculateDomain = (dataArray, minKey, maxKey, margin = 0) => {
        const minValues = dataArray.map(item => item[minKey]);
        const maxValues = dataArray.map(item => item[maxKey]);

        let minValue = Math.min(...minValues);
        let maxValue = Math.max(...maxValues);

        const adjustedMin = minValue - margin;
        const adjustedMax = maxValue + margin;

        const range = adjustedMax - adjustedMin;

        if (range < 1) {
            minValue = parseFloat(adjustedMin.toFixed(2));
            maxValue = parseFloat(adjustedMax.toFixed(2));
        }

        else {
            minValue = Math.floor(adjustedMin);
            maxValue = Math.ceil(adjustedMax);
        }

        return [minValue, maxValue];
    };

    useEffect(() => {
        if (data && data.length > 0) {
            const maxTemp = Math.max(...data.map(d => d.temperatureMax));
            const minTemp = Math.min(...data.map(d => d.temperatureMin));
            const avgTemp = data.reduce((sum, d) => sum + d.temperature, 0) / data.length;

            const totalPrecip = data.reduce((sum, d) => sum + d.precipitation, 0);
            const precipOccurrences = data.filter(d => d.precipitation > 0).length;
            const probabilityOfRain = (precipOccurrences / data.length) * 100;

            const maxWindSpeed = Math.max(...data.map(d => d.windSpeedMax));
            const avgWindSpeed = data.reduce((sum, d) => sum + d.windSpeed, 0) / data.length;
            const avgWindDirection = data.reduce((sum, d) => sum + d.windDirection, 0) / data.length;

            const avgCloudCover = data.reduce((sum, d) => sum + d.cloud, 0) / data.length;

            setInsights({
                maxTemp,
                minTemp,
                avgTemp,
                totalPrecip,
                probabilityOfRain,
                maxWindSpeed,
                avgWindSpeed,
                avgWindDirection,
                avgCloudCover,
            });

        } 
        
        else {
            setInsights(null);
        }
    }, [data]);
    // const { user_id, logout } = useAuth();

    // useEffect(() => {
    //     if (trackers.length > 0) {
    //         setTracker(trackers[0]);
    //         setData(trackers[0].data);
    //         setAlerts(trackers[0].alerts);
    //     }
    // }, [trackers]);

    // useEffect(() => {
    //     console.log('------------IN EXPANDED TRACKER ACTIVE VENUE CHANGED TO', activeVenue)
    // }, [activeVenue])

    // NOT USED
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const today = new Date();
    //         const oneWeekFuture = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    //         const oneMonthFuture = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);

    //         const fetchedData = await fetchDataFromServer(user_id, today, oneMonthFuture, unitSettings);
    //         setServerData(fetchedData);

    //         const weekData = filterDataByDateRange(fetchedData, today, oneWeekFuture);
    //         const monthData = filterDataByDateRange(fetchedData, today, oneMonthFuture);

    //         setWeekData(weekData);
    //         setMonthData(monthData);
    //     };
    //     fetchData();
    // }, []);

    useEffect(() => {
        // console.log('In expanded tracker active venue is:', activeVenue)
        // Do not load the venue name from the user's profile if they have selected
        // a venue in the settings page
        // if (activeVenue != null && activeVenue.length > 0) {
        //     return
        // }

        // if (currentVenue != null && currentVenue.length > 0) {
        //     return
        // }

        const fetchVenue = async () => {
            const venueForUser = await fetchVenueForUser(user_id);
            setSelectedVenue(venueForUser);
            console.log('SET SELECTED VENUE:', venueForUser);
        };
        fetchVenue();
    }, [])

    useEffect(() => {
        const getVenues = async () => {
            // const data = await fetch(`https://junbispark.net/get_all_venues/?user_id=${user_id}`).then((response) => {
            //     return response.json();
            // }).then((data) => {
            //     setData(data['venues'].map((val) => { return <option value={val}>{val}</option> }));
            //     setVenue(data['venues'][0]);
            // });
            const data = await getVenuesList(user_id)
            setVenueList(data['venues'].map((val) => { return <option value={val}>{val}</option> }));
            // setVenue(data['venues'][0]);
        }
        getVenues();
    }, []);

    useEffect(() => {
        const fetchUnits = async () => {
            const units = await fetchUnitSettingsForUser(user_id);
            if (!units) {
                console.log('Unit settings fetch returned null, using default units');
                return;
            }
            // Instead of setting units to whatever the backend returns,
            // only use the units from temperature to set units for precipitation and wind
            // TODO: once backend bug is fixed then just setUnitSettings to whatever the backend returns
            
            // setUnitSettings(units);
            
            // If the temperature is farhenheit, assume imperial
            if (units[TEMP_UNIT_KEY] == FAHRENHEIT) {
                setUnitSystem(IMPERIAL);
                setUnitSettings(IMPERIAL_UNITS);
            } else {
                setUnitSystem(METRIC);
                setUnitSettings(METRIC_UNITS);
            }
            console.log('SET UNITS:', units);
        };
        fetchUnits();
    }, [])

    useEffect(() => {
        const refetchData = async () => {
            const fetchedData = await fetchDataFromServer(user_id, startDate, endDate, unitSettings);
            setData(fetchedData);
        };
        refetchData();
    }, [startDate, endDate, selectedVenue, unitSettings]);

    useEffect(() => {
        setInterval(calculateInterval(startDate, endDate));
    }, [startDate, endDate]);

    useEffect(() => {
        if (timeRange == '1D') {
            setEndDate(new Date(startDate.getTime() + ONE_DAY_FUTURE))
            // setEndDate(new Date(new Date().getTime() + ONE_DAY_FUTURE));
        } else if (timeRange == '3D') {
            setEndDate(new Date(startDate.getTime() + 3 * ONE_DAY_FUTURE))
            // setEndDate(new Date(new Date().getTime() + 3 * ONE_DAY_FUTURE));
        } else if (timeRange == '1W') {
            setEndDate(new Date(startDate.getTime() + ONE_WEEK_FUTURE))
            // setEndDate(new Date(new Date().getTime() + ONE_WEEK_FUTURE));
        } else if (timeRange == '1M') {
            setEndDate(new Date(startDate.getTime() + 30 * ONE_DAY_FUTURE))
            // setEndDate(new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000));
        }
    }, [timeRange]);

    const handleStartDateChange = (date) => {
        setStartDate(new Date(date));
    };

    const handleEndDateChange = (date) => {
        if (date && date.getTime() === endDate?.getTime()) {
            setEndDate(null);
        } else if (date - startDate <= 0) {
            // end date one day after start date
            setEndDate(new Date(startDate.getTime() + 24 * 60 * 60 * 1000));
        } else {
            setEndDate(new Date(date));
        }
    };

    const handleVenueSelect = async (event) => {
        const venue = event.target.value
        console.log('Selected venue', venue)
        await saveVenueToUser(user_id, venue)
        setSelectedVenue(event.target.value)
    }

    const handleResetDates = () => {
        setStartDate(new Date());
        setEndDate(null);
    };

    const handleUnitToggle = async () => {
        let unitsToSaveToBackend = null;
        if (unitSystem == IMPERIAL) {
            // If the unit system is currently imperial, set everything to metric
            setUnitSystem(METRIC);
            setUnitSettings(METRIC_UNITS);
            unitsToSaveToBackend = METRIC_UNITS;
        } else {
            // If the unit system is currently metric, set everything to imperial
            setUnitSystem(IMPERIAL);
            setUnitSettings(IMPERIAL_UNITS);
            unitsToSaveToBackend = IMPERIAL_UNITS;
        }

       await saveUnitsToBackend(user_id, unitsToSaveToBackend);
    }
    const DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
      });
    
    L.Marker.prototype.options.icon = DefaultIcon;
    return (
        <div className="dashboard">
            <div className="side-menu">
                <Routes>
                    <Route path="/settings" element={<SettingsPage />} /> 
                </Routes>
                <a href="#home" className="side-menu-item">
                    <img className="small-icon" src={IconHome} alt="Home" />
                </a>
                { /* <a href={`/tracker/${user_id}`} className="side-menu-item">
                    <img className="small-icon" src={IconTracker} alt="Tracker" />
                </a> */}
                <a href={`/settings/${user_id}`} className="side-menu-item">
                    <img className="small-icon" src={IconCog} alt="Settings" />
                </a>
            </div>
            
            {/* Page content */}
            <div className="page-container">
                <h1>Weather Forecasts</h1>
                {/* Venue name and date filter box */}
                <div className="date-filter-box">
                    <div className="venue-name-group">
                        <img className="icon" src={IconPin} alt="Venue" />
                        <p className="selected-venue-text">{selectedVenue}</p>
                        {/* <select onChange={handleVenueSelect}>
                            {venueList}
                        </select> */}
                    </div>
                    <div className="custom-date-group">
                        {/* <img className="icon" src={IconCal} alt="Start Date" /> */}
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => handleStartDateChange(date)}
                            customInput={<CustomDatePickerInput className="custom-date-picker" />}
                        />
                    </div>
                    <div className="date-input">
                        <DatePicker
                            className="date-picker"
                            selected={endDate}
                            onChange={(date) => handleEndDateChange(date)}
                            placeholderText="End date"
                        />
                    </div>
                </div>
                <div className="tabs">
                    <button className="tab-button" onClick={() => setActiveTab('temperature')}>
                        <img src={activeTab === 'temperature' ? IconTemp : IconTempLite} alt="Temperature" />
                    </button>
                    <button className="tab-button" onClick={() => setActiveTab('precipitation')}>
                        <img src={activeTab === 'precipitation' ? IconRain : IconRainLite} alt="Precipitation" />
                    </button>
                    <button className="tab-button" onClick={() => setActiveTab('allSky')}>
                        <img src={activeTab === 'allSky' ? IconSolar : IconSolarLite} alt="Solar Radiation" />
                    </button>
                    <button className="tab-button" onClick={() => setActiveTab('cloud')}>
                        <img src={activeTab === 'cloud' ? IconCloud : IconCloudLite} alt="Cloud Cover" />
                    </button>
                    <button className="tab-button" onClick={() => setActiveTab('rh2m')}>
                        <img src={activeTab === 'rh2m' ? IconHumidity : IconHumidityLite} alt="Relative Humidity" />
                    </button>
                    <button className="tab-button" onClick={() => setActiveTab('windSpeed')}>
                        <img src={activeTab === 'windSpeed' ? IconWind : IconWindLite} alt="Wind Speed" />
                    </button>

                    <div className="tab-divider"></div>

                    <button className="tab-button" onClick={() => setActiveTab('temperatureMin')}>
                        <img src={activeTab === 'temperatureMin' ? IconTempMin : IconTempMinLite} alt="Temperature Min" />
                    </button>

                    <button className="tab-button" onClick={() => setActiveTab('temperatureMax')}>
                        <img src={activeTab === 'temperatureMax' ? IconTempMax : IconTempMaxLite} alt="Temperature Max" />
                    </button>

                </div>

                { /* refactor tick formatter for x-axis, just resuing same line */}

                <div className="graph-container">
                    {activeTab === 'temperature' && (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={data} margin={{ top: 16, right: 32, left: 8, bottom: 16 }}>
                                {(() => {
                                    if (!data || data.length === 0) return null;
                                    const tempDomain = calculateDomain(data, 'temperatureMin', 'temperatureMax', 0);
                                    return (
                                        <>
                                            <defs>
                                                <linearGradient id="colorTemp" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#FFA46B" stopOpacity={1} />
                                                    <stop offset="95%" stopColor="#FFA46B" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>
                                            <XAxis 
                                                dataKey="date" 
                                                axisLine={false} 
                                                interval={interval} 
                                                tickFormatter={(value) => {
                                                    const date = new Date(value);
                                                    const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

                                                    if (dateRangeInDays <= 1) {
                                                        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                                                    } 

                                                    else {
                                                        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
                                                    }
                                                }}
                                            />
                                            <YAxis yAxisId="left" axisLine={false} domain={tempDomain} tickFormatter={(value) => value.toFixed(1)}> { /* temporary fix */ }
                                                <Label 
                                                    value={`Temperature (°${unitSettings[TEMP_UNIT_KEY]})`}
                                                    angle={-90} 
                                                    position="insideLeft" 
                                                    style={{ textAnchor: 'middle' }} 
                                                    dy={0} 
                                                />
                                            </YAxis>
                                            <Tooltip content={<CustomTooltip minKey="temperatureMin" maxKey="temperatureMax" />} />
                                            <Area yAxisId="left" type="monotone" dataKey="temperature" stroke="#FFA46B" fillOpacity={1} fill="url(#colorTemp)" />
                                        </>
                                    );
                                })()}
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
                    {activeTab === 'precipitation' && (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={data} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                                {(()=> {
                                    if (!data || data.length === 0) return null;
                                        const precipDomain = calculateDomain(data, 'precipitationMin', 'precipitationMax', 0);
                                        return (
                                            <>
                                            <defs>
                                                <linearGradient id="colorPrecip" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#5ED8FF" stopOpacity={1} />
                                                    <stop offset="95%" stopColor="#5ED8FF" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>

                                            <XAxis 
                                                dataKey="date" 
                                                axisLine={false} 
                                                interval={interval} 
                                                tickFormatter={(value) => {
                                                    const date = new Date(value);
                                                    const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

                                                    if (dateRangeInDays <= 1) {
                                                        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                                                    } 

                                                    else {
                                                        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
                                                    }
                                                }}
                                            />

                                            <YAxis yAxisId="left" axisLine={false} domain={precipDomain}>
                                                <Label 
                                                    value={`Precipitation (${unitSettings[PRECIP_UNIT_KEY]})`} 
                                                    angle={-90} 
                                                    position="insideLeft" 
                                                    style={{ textAnchor: 'middle' }} 
                                                    dy={0} 
                                                />
                                            </YAxis>
                                            <Tooltip content={<CustomTooltip minKey="precipitationMin" maxKey="precipitationMax" />} />
                                            <Area yAxisId="left" type="monotone" dataKey="precipitation" stroke="#5ED8FF" fillOpacity={1} fill="url(#colorPrecip)" />
                                        </>
                                    );
                                })()}
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
                    {activeTab === 'allSky' && (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={data} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                                {(() => {
                                    if (!data || data.length === 0) return null;
                                    const wattsDomain = calculateDomain(data, 'wattsMin', 'wattsMax', 10);
                                    return (
                                        <>
                                            <defs>
                                                <linearGradient id="colorTemp" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#FFF175" stopOpacity={1} />
                                                    <stop offset="95%" stopColor="#FFFBD6" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>

                                            <XAxis 
                                                dataKey="date" 
                                                axisLine={false} 
                                                interval={interval} 
                                                tickFormatter={(value) => {
                                                    const date = new Date(value);
                                                    const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

                                                    if (dateRangeInDays <= 1) {
                                                        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                                                    } 

                                                    else {
                                                        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
                                                    }
                                                }}
                                            />

                                            <YAxis yAxisId="left" axisLine={false} domain={wattsDomain}>
                                                <Label 
                                                    value="Solar Radiation (Watts)" 
                                                    angle={-90} 
                                                    position="insideLeft" 
                                                    style={{ textAnchor: 'middle' }} 
                                                    dy={0} 
                                                />
                                            </YAxis>
                                            <Tooltip content={<CustomTooltip minKey="wattsMin" maxKey="wattsMax" />} />
                                            <Area yAxisId="left" type="monotone" dataKey="watts" stroke="#FFF175" fillOpacity={1} fill="url(#colorTemp)" />
                                        </>
                                    );
                                })()}
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
                    {activeTab === 'cloud' && (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={data} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                                {(() => {
                                    if (!data || data.length === 0) return null;
                                    const cloudDomain = calculateDomain(data, 'cloudMin', 'cloudMax', 0);
                                    return (
                                        <>
                                            <defs>
                                                <linearGradient id="colorTemp" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#B8B8B8" stopOpacity={1} />
                                                    <stop offset="95%" stopColor="#D3F4FF" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>
                                            <XAxis 
                                                dataKey="date" 
                                                axisLine={false} 
                                                interval={interval} 
                                                tickFormatter={(value) => {
                                                    const date = new Date(value);
                                                    const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

                                                    if (dateRangeInDays <= 1) {
                                                        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                                                    } 

                                                    else {
                                                        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
                                                    }
                                                }}
                                            />
                                            <YAxis yAxisId="left" axisLine={false} domain={cloudDomain}>
                                                <Label 
                                                    value="Cloud Cover (%)" 
                                                    angle={-90} 
                                                    position="insideLeft" 
                                                    style={{ textAnchor: 'middle' }} 
                                                    dy={0} 
                                                />
                                            </YAxis>
                                            <Tooltip content={<CustomTooltip minKey="cloudMin" maxKey="cloudMax" />} />
                                            <Area yAxisId="left" type="monotone" dataKey="cloud" stroke="#B8B8B8" fillOpacity={1} fill="url(#colorTemp)" />
                                        </>
                                    );
                                })()}  
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
                    {activeTab === 'rh2m' && (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={data} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                                {(() => {
                                    if (!data || data.length === 0) return null;
                                    const rh2mDomain = calculateDomain(data, 'rh2mMin', 'rh2mMax', 0.0);
                                    return (
                                        <>
                                            <defs>
                                                <linearGradient id="colorTemp" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#7DB9FF" stopOpacity={1} />
                                                    <stop offset="95%" stopColor="#D0E5FF" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>
                                            <XAxis 
                                                dataKey="date" 
                                                axisLine={false} 
                                                interval={interval} 
                                                tickFormatter={(value) => {
                                                    const date = new Date(value);
                                                    const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

                                                    if (dateRangeInDays <= 1) {
                                                        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                                                    } 

                                                    else {
                                                        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
                                                    }
                                                }}
                                            />
                                            <YAxis yAxisId="left" axisLine={false} domain={rh2mDomain} tickFormatter={(value) => value.toFixed(1)}>
                                                <Label 
                                                    value="Relative Humidity (%)" 
                                                    angle={-90} 
                                                    position="insideLeft" 
                                                    style={{ textAnchor: 'middle' }} 
                                                    dy={0} 
                                                />
                                            </YAxis>
                                            <Tooltip content={<CustomTooltip minKey="rh2mMin" maxKey="rh2mMax" />} />
                                            <Area yAxisId="left" type="monotone" dataKey="rh2m" stroke="#7DB9FF" fillOpacity={1} fill="url(#colorTemp)" />
                                        </>
                                    );
                                })()}
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
                    {activeTab === 'windSpeed' && (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={data} margin={{ top: 16, right: 32, left: 0, bottom: 16 }}>
                                {(() => {
                                    if (!data || data.length === 0) return null;
                                    const windSpeedDomain = calculateDomain(data, 'windSpeedMin', 'windSpeedMax', 1);
                                    return (
                                        <>
                                            <defs>
                                                <linearGradient id="colorTemp" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#BBD531" stopOpacity={1} />
                                                    <stop offset="95%" stopColor="#EFFFB1" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>
                                            <XAxis 
                                                dataKey="date" 
                                                axisLine={false} 
                                                interval={interval} 
                                                tickFormatter={(value) => {
                                                    const date = new Date(value);
                                                    const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

                                                    if (dateRangeInDays <= 1) {
                                                        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                                                    } 

                                                    else {
                                                        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
                                                    }
                                                }}
                                            />
                                            <YAxis yAxisId="left" axisLine={false} domain={windSpeedDomain}>
                                                <Label 
                                                    value={`Wind Speed (${unitSettings[WIND_UNIT_KEY]})`}
                                                    angle={-90} 
                                                    position="insideLeft" 
                                                    style={{ textAnchor: 'middle' }} 
                                                    dy={0} 
                                                />
                                            </YAxis>
                                            <Tooltip content={<CustomTooltip minKey="windSpeedMin" maxKey="windSpeedMax" />} />
                                            <Area yAxisId="left" type="monotone" dataKey="windSpeed" stroke="#BBD531" fillOpacity={1} fill="url(#colorTemp)" />
                                        </>
                                    );
                                })()}
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
                    {activeTab === 'temperatureMin' && (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={data} margin={{ top: 16, right: 32, left: 8, bottom: 16 }}>
                            {(() => {
                                if (!data || data.length === 0) return null;
                                const tempMinDomain = calculateDomain(data, 'temperatureMin', 'temperatureMin', 0);
                                return (
                                    <>
                                        <defs>
                                            <linearGradient id="colorTempMin" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#6B95FF" stopOpacity={1} />
                                                <stop offset="95%" stopColor="#6B95FF" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>

                                        <XAxis
                                            dataKey="date"
                                            axisLine={false}
                                            interval={interval}
                                            tickFormatter={(value) => {
                                                const date = new Date(value);
                                                const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

                                                if (dateRangeInDays <= 1) {
                                                    return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                                                } 

                                                else {
                                                    return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
                                                }
                                            }}
                                        />

                                        <YAxis yAxisId="left" axisLine={false} domain={tempMinDomain}>
                                            <Label
                                                value={`Temperature Min (°${unitSettings[TEMP_UNIT_KEY]})`}
                                                angle={-90} 
                                                position="insideLeft" 
                                                style={{ textAnchor: 'middle' }}
                                                dy={0}
                                            />
                                        </YAxis>
                                        <Tooltip content={<CustomTooltip minKey="temperatureMin" maxKey="temperatureMin" />} />
                                        <Area yAxisId="left" type="monotone" dataKey="temperatureMin" stroke="#6B95FF" fillOpacity={1} fill="url(#colorTempMin)" />
                                    </>
                                );
                            })()}
                            </AreaChart>
                        </ResponsiveContainer>
                    )}

                    {activeTab === 'temperatureMax' && (
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart data={data} margin={{ top: 16, right: 32, left: 8, bottom: 16 }}>
                                {(() => {
                                    if (!data || data.length === 0) return null;
                                    const tempMaxDomain = calculateDomain(data, 'temperatureMax', 'temperatureMax', 0);
                                    return (
                                        <>
                                            <defs>
                                                <linearGradient id="colorTempMax" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#FF6B6B" stopOpacity={1} />
                                                    <stop offset="95%" stopColor="#FF6B6B" stopOpacity={0} />
                                                </linearGradient>
                                            </defs>
                                            
                                            <XAxis 
                                                dataKey="date" 
                                                axisLine={false} 
                                                interval={interval} 
                                                tickFormatter={(value) => {
                                                    const date = new Date(value);
                                                    const dateRangeInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

                                                    if (dateRangeInDays <= 1) {
                                                        return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
                                                    } 

                                                    else {
                                                        return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
                                                    }
                                                }}
                                            />

                                            <YAxis yAxisId="left" axisLine={false} domain={tempMaxDomain}>
                                                <Label 
                                                    value={`Temperature Max (°${unitSettings[TEMP_UNIT_KEY]})`}
                                                    angle={-90} 
                                                    position="insideLeft" 
                                                    style={{ textAnchor: 'middle' }} 
                                                    dy={0} 
                                                />
                                            </YAxis>

                                            <Tooltip content={<CustomTooltip minKey="temperatureMax" maxKey="temperatureMax" />} />
                                            <Area yAxisId="left" type="monotone" dataKey="temperatureMax" stroke="#FF6B6B" fillOpacity={1} fill="url(#colorTempMax)" />
                                        </>
                                    );
                                })()}
                            </AreaChart>
                        </ResponsiveContainer>
                    )}
                </div>
                <div className="time-range-container">
                <button
                    className={`time-range-button ${timeRange === '1D' ? 'selected' : ''}`}
                    onClick={() => setTimeRange('1D')}
                >
                    1D
                </button>
                <button
                    className={`time-range-button ${timeRange === '3D' ? 'selected' : ''}`}
                    onClick={() => setTimeRange('3D')}
                >
                    3D
                </button>
                <button
                    className={`time-range-button ${timeRange === '1W' ? 'selected' : ''}`}
                    onClick={() => setTimeRange('1W')}
                >
                    1W
                </button>
                <button
                    className={`time-range-button ${timeRange === '1M' ? 'selected' : ''}`}
                    onClick={() => setTimeRange('1M')}
                >
                    1M
                </button>
            </div>

            <div className="alerts">
                {alerts.map((alert, index) => (
                    <div key={index} className="alert">
                        <span>{alert.type}: {alert.value}</span>
                        <p>{alert.message}</p>
                    </div>
                ))}
            </div>
        </div>

        <div className="insights-tab-container">
            <h1>Weather Insights</h1>
            <div className="insights-tab-data">
                {insights ? (
                    <div className="insights-flex-container">
                        <div className="insight-item">
                            <img src={IconTempLite} alt="Temperature Icon" className="insight-icon"/>
                            <p className="insight-value">{insights.maxTemp.toFixed(2)}°{unitSettings[TEMP_UNIT_KEY]}</p>
                            <p className="insight-label">Max Temperature</p>
                        </div>

                        <div className="insight-item">
                            <img src={IconRainLite} alt="Rain Icon" className="insight-icon"/>
                            <p className="insight-value">{insights.totalPrecip.toFixed(2)} {unitSettings[PRECIP_UNIT_KEY]}</p>
                            <p className="insight-label">Total Precipitation</p>
                            <p className="insight-value">{insights.probabilityOfRain.toFixed(0)}%</p>
                            <p className="insight-label">Probability of Rain</p>
                        </div>

                        <div className="insight-item">
                            <img src={IconWindLite} alt="Wind Icon" className="insight-icon" />
                            <p className="insight-value">{insights.maxWindSpeed.toFixed(2)} {unitSettings[WIND_UNIT_KEY]}</p>
                            <p className="insight-label">Max Wind Speed</p>
                            <p className="insight-value">{getWindDirection(insights.avgWindDirection)}</p>
                            <p className="insight-label">Avg Wind Direction</p>
                        </div>

                        <div className="insight-item">
                            <img src={IconCloudLite} alt="Cloud Cover Icon" className="insight-icon" />
                            <p className="insight-value">{(insights.avgCloudCover).toFixed(0)}%</p>
                            <p className="insight-label">Avg Cloud Cover</p>
                        </div>
                    </div>
                ) : (
                    <p>No data available for insights.</p>
                )}

            </div>
        </div>
    </div>

    );
};

export default ExpandedTracker;