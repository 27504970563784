import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import {useAuth} from '../utils/authprovider';

import SettingsPage from './SettingsPage';

import IconCog from '../assets/icon-cog.png';
import IconTracker from '../assets/icon-tracker.png';
import IconHome from '../assets/icon-home.png';

import '../styles/TrackerPage.css';

const TrackerPage = () => {
    const { user_id } = useParams();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [trackers, setTrackers] = useState([]);

    /* useEffect(()=> {
        if (!user || user.uid !== user_id) {
            navigate('/signin')
        }
    }, [user, user_id, navigate]); */

    useEffect(() => {
        const fetchTrackers = async () => {
            const fetchedTrackers = await fetch(`/api/trackers`).then((res) => res.json());
            setTrackers(fetchedTrackers);
        };

        fetchTrackers();
    }, [user_id]);

    const handleLogout = async () => {
        logout();
        navigate('/signin')
    };

    const renderSideMenu = () => {
        return (
            <div className="side-menu">
                <Routes>
                    <Route path="/settings" element={<SettingsPage />} />
                </Routes>
                <a href={`/dashboard/${user_id}`} className="side-menu-item">
                    <img className="small-icon" src={IconHome} alt="Home" />
                </a>
                <a href={`/tracker/${user_id}`} className="side-menu-item">
                    <img className="small-icon" src={IconTracker} alt="Tracker" />
                </a>
                <a href={`/settings/${user_id}`} className="side-menu-item">
                    <img className="small-icon" src={IconCog} alt="Settings" />
                </a>
            </div>
        );
    };

    return (
        <div className="trackers-page">
            {renderSideMenu()}
            <div className="page-container">
                <h1>Trackers Page</h1>
                <div className="trackers-list">
                    {trackers.map((tracker) => (
                        <div className="tracker-item" key={tracker.id}>
                            <h2>{tracker.title}</h2>
                            <p>{tracker.description}</p>
                        </div>
                    ))}
                    <button className="primary-button" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TrackerPage;

