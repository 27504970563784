// src/contexts/AuthContext.js
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from "js-cookies";

const AuthContext = React.createContext();

export const VenueContext = React.createContext(null);


export function useAuth() {
  return useContext(AuthContext);
}



export function AuthProvider({ children }) {
  // const [cookie, setCookie] = useState(null);

  const logout = () => {Cookies.removeItem("session_cookie",'/')}//setItem("session_cookie",Cookies.,0,'/')}

  const value = {
    // cookie,
    // setCookie,
    logout,
  };


  return (
    <AuthContext.Provider value={value}>
    {/* <AuthContext.Provider> */}
      {children}
    </AuthContext.Provider>
  );
}

export function ProtectedRoute({ component: element, ...rest }) {
    // const { cookie } = useAuth();
    // console.log(cookie)
    const cookie = Cookies.getItem("session_cookie");
    return (
          
        cookie ? <Outlet/> : <Navigate to="/signin" />
      
    );
  }
  
  export default ProtectedRoute;