import React from 'react';
import '../styles/CopyrightFooter.css'; 

function CopyrightFooter({className}) {
  const footerClass = `footer-container ${className}`;
  return (
    <div className={footerClass}>
      <div className="footer-content">
        <span>Copyright © 2024 Climformatics. All rights reserved.</span>
        <a href="/terms" className="footer-link">Terms of Use & Privacy Policy</a>
      </div>
    </div>
  );
}

export default CopyrightFooter;