import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/VenueSelection.css';

export const getVenuesList = async (user_id) => {
    try {
        const response = await fetch(`https://junbispark.net/get_all_venues/?user_id=${user_id}`)
        const result = await response.json()
        return result
    } catch (error) {
        console.log('Fetching list of venues got error returning empty list:', error)
        return {'venues': []}
    }
}

function VenueSelection() {
    const [selectedVenue, setVenue] = useState("");
    const navigate = useNavigate();
    const [dataToShow, setData] = useState([]);
    let { user_id } = useParams();

    const handleVenueSelection = (event) => {
        setVenue(event.target.value);
    }

    const handleCancel = () => {
        navigate('/signin'); 
    }

    const handleVenueSubmission = async () => {
        await fetch(`https://junbispark.net/select_venue/?user_id=${user_id}&venue_name=${selectedVenue}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response['status'] === 'success') {
                navigate(`/dashboard/${user_id}`);
            }
        });
    }

    useEffect(() => {
        const getData = async () => {
            // const data = await fetch(`https://junbispark.net/get_all_venues/?user_id=${user_id}`).then((response) => {
            //     return response.json();
            // }).then((data) => {
            //     setData(data['venues'].map((val) => { return <option value={val}>{val}</option> }));
            //     setVenue(data['venues'][0]);
            // });
            const data = await getVenuesList(user_id)
            setData(data['venues'].map((val) => { return <option value={val}>{val}</option> }));
            setVenue(data['venues'][0]);
        }
        getData();
    }, []);

    useEffect(() => {
        document.body.classList.add('venue-selection-body');
        return () => {
          document.body.classList.remove('venue-selection-body');
        };
      }, []);

    return (
        <body className="venue-selection-body">
        <div className="venue-selection-container">
            <div className="logo">BestDay2Marry</div>
            <p>What is the name of your venue?</p>
            <div className="venue_list">
                <select onChange={handleVenueSelection}>
                    {dataToShow.map((val) => { return val })}
                </select>
            </div>
            <button type="submit" className="primary-button" onClick={handleVenueSubmission}>
                Submit
            </button>
            <button type="submit" className="secondary-button" onClick={() => navigate(`/add_venue/${user_id}`)}>
                My venue isn't here
            </button>
            <button type="button" className="tertiary-button" onClick={handleCancel}>Cancel</button>
        </div>
        </body>
    );
}

export default VenueSelection;
