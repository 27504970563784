

export const fetchVenueForUser = async (userId) => {
    try {
        const url = `https://junbispark.net/get_user_state_assignment/?user_id=${userId}`
        const response = await fetch(url)
        const result = await response.json()
        if (result['status'] === 'success') {
            return result['venue_name']
        }
    } catch (error) {
        console.error('Error fetching venue name for user', userId, ':', error);
    }
    return null;
}

export const saveVenueToUser = async (user_id, venueName) => {
    try {
        const response = await fetch(`https://junbispark.net/select_venue/?user_id=${user_id}&venue_name=${venueName}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const result = await response.json()
        if (result['status'] !== 'success') {
            console.log('Saving venue to user profile got non-success response:', result)
        }
    } catch (error) {
        console.log('Saving venue to user profile got error:', error)
    }
}